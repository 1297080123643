import { MemberB2cAuthResponse } from './../models/member-b2c-auth-response';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { Observable, of, throwError } from 'rxjs';

import { StorageService } from './storage.service';
import { RefreshTokenResponse } from '../models/refresh-token-response';
import { B2cMemberXrefService } from './b2c-member-xref.service';
import { MemberB2cAuthService } from './member-b2c-auth.service';

const API_URL = environment.urlBase;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private b2cXrefService: B2cMemberXrefService,
    private memberB2cAuthService: MemberB2cAuthService
  ) { }

  public b2cLogin(accessToken: string): Observable<MemberB2cAuthResponse | null> {
    return this.b2cXrefService.GetB2cMemberXref(accessToken)
      .pipe(switchMap(xrefResponse => {
        if (!xrefResponse) {
          return of(null);
        }
        return this.memberB2cAuthService.GetB2cMemberAuth(accessToken)
          .pipe(map(response => {
            return response;
          }));
      }),
        catchError(error => {
          if (error.status === 500) {
            this.router.navigate(['/error']);
          }
          return throwError(error);
        }));
  }

  public b2cRefreshToken(refreshToken: string): Observable<MemberB2cAuthResponse | null> {
    return this.memberB2cAuthService.GetB2cMemberAuth(refreshToken)
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          if (error.status === 500) {
            this.router.navigate(['/error']);
          }
          return throwError(error);
        }
        ));
  }

  public refreshToken(token: string): Observable<boolean> {
    const headers1 = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<RefreshTokenResponse>(API_URL + '/v3/authentication/users/refresh', 'RefreshToken=' + token, { headers: headers1 })
      .pipe(map((response) => {
        if (response.isValid === true) {
          this.storageService.accessToken = response.accessToken;
          this.storageService.refreshToken = response.refreshToken;
          return true;
        }
        return false;
      }),
        catchError(error => {
          if (error.status === 500) {
            this.router.navigate(['/error']);
          }
          return throwError(error);
        }));
  }

  public get isLoggedIn(): boolean {
    if (this.storageService.accessToken && this.storageService.sdkLoggedOnConsumer) {
      return true;
    }
    return false;
  }
}
