// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlBase: 'https://bluekc-nonprod-test.apigee.net',
  jwtWhitelistUrl: 'bluekc-nonprod-test.apigee.net',
  memberUrl:'https://t2members.bluekc.com',
  splunkUrlBase: 'https://tsplnksh2.test.bcbskc.com:9997',
  auth_token: '85bfe3cd-b73b-461d-86d2-8fdd6b462826',
  apimBase: 'https://apis.test.bluekc.com',
  //awsdk config
  sdkApiKey: 'b15fc132',
  baseUrl: 'https://iot89.amwellintegration.com/',
  locale: 'us-EN',
  isIVRCallbackEnabled: true,

    // MSAL config
    msalTenantName: 'bluekcnonprod',
    msalTenantId: 'bluekcnonprod.onmicrosoft.com',
    msalClientId: '620c78ed-d951-43bd-860b-4efe54d5578f',
    msalPolicySignIn: 'B2C_1A_EXTERNALPORTAL_SIGNUP_SOCIALS',
    msalScopes: 'https://bluekcnonprod.onmicrosoft.com/5263e9a1-1da0-4cd8-8d3c-b75825422bdf/myBlueKC.Exec',
    instrumentationKey: '85f4c4a9-c4ef-4f48-b948-cdd3d445dd5f'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
