<footer>
    <div class="footer-content">
        <ul>
            <li class="desktop"><a href="https://www.mybluekc.com/" title="Blue Cross Blue Shield of Kansas City" target="_blank">MyBlueKC.com</a></li>
            <li class="mobile"><a href="https://www.mybluekc.com/" title="BlueKC.com" target="_blank">MyBlueKC.com</a></li>
            <li><a href="https://www.bluekc.com/consumer/privacy-security.html" title="Privacy & Security" target="_blank">Privacy &amp; Security</a></li>
            <li><a href="https://www.bluekc.com/consumer/terms-conditions.html" title="Terms & Conditions" target="_blank">Terms &amp; Conditions</a></li>
            <li class="last"><a href="https://static.americanwell.com/app/uploads/2021/05/NOTICE-OF-PRIVACY-PRACTICES-for-OCN-April-2021.pdf" title="Privacy Practices" target="_blank">Privacy Practices</a></li>
        </ul>
        <div class="clear"></div>
        <p>An independent licensee of the Blue Cross and Blue Shield Association.<br /> &copy; {{year | date :'yyyy'}} Blue Cross and Blue Shield
            of Kansas City. All rights reserved.</p>
        <p>v2024.07.22</p>
    </div>
</footer>
