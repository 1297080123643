import { MemberB2cAuthResponse } from '../models/member-b2c-auth-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const API_URL = environment.urlBase;

@Injectable({
  providedIn: 'root'
})
export class MemberB2cAuthService {

  constructor(private http: HttpClient) { }

  public GetB2cMemberAuth(accessToken: string): Observable<MemberB2cAuthResponse> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + accessToken);
    return this.http.get<MemberB2cAuthResponse>(API_URL + '/v1/memberb2cauthorization', { headers: headers })
      .pipe(
        catchError(err => throwError(err))
      );
  }
}
