import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeContactMethodRequest } from '../models/change-contact-method-request';
import { CommunicationResult } from '../models/communication-result';

const API_URL = environment.urlBase;

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private http: HttpClient, private storage: StorageService) { }

  public GetHomeEmail(): Observable<CommunicationResult> {
    const url = API_URL + '/v1/contacts/member/contact-type/HOMEEMAIL';
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (this.storage.accessToken !== undefined) {
      headers = headers.set('Authorization', 'Bearer ' + this.storage.accessToken);
    }
    return this.http.get<CommunicationResult>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  public GetCellPhone(): Observable<CommunicationResult> {
    const url = API_URL + '/v1/contacts/member/contact-type/CELLPHONE';
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (this.storage.accessToken !== undefined) {
      headers = headers.set('Authorization', 'Bearer ' + this.storage.accessToken);
    }
    return this.http.get<CommunicationResult>(url).pipe(
      catchError(err => throwError(err))
    );
  }


  public SetContactMethod(request: ChangeContactMethodRequest): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (this.storage.accessToken !== undefined) {
      headers = headers.set('Authorization', 'Bearer ' + this.storage.accessToken);
    }
    return this.http.put(API_URL + '/v1/contacts/member', JSON.stringify(request), { headers }).pipe(
      catchError(err => throwError(err))
    );
  }

  public CreateEmailRequest(email: string) {
    const emailRequest = new ChangeContactMethodRequest();
    emailRequest.ChangeUserId = 'MemberPortal';
    emailRequest.ContactTypeId = 'HOMEEMAIL';
    emailRequest.ContactTypeValue = email;
    return emailRequest;
  }

  public CreatePhoneRequest(phone: string) {
    const phoneRequest = new ChangeContactMethodRequest();
    phoneRequest.ChangeUserId = 'MemberPortal';
    phoneRequest.ContactTypeId = 'CELLPHONE';
    phoneRequest.ContactTypeValue = phone;
    return phoneRequest
  }
}
